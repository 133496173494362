<template>
    <div class="line">
        <v-select :background-color="color" :items="possibleSubjects" class="pa-1" label="Sujet" solo
                  v-model="myRequirement.subject"/>

        <v-select :background-color="color" :items="confKeySelectItems" class="pa-1" label="Clé" solo v-if="needsKeyName"
                      v-model="myRequirement.keyName"/>

        <v-select :background-color="color" :items="availableOperators" class="pa-1" label="Opérateur" solo
                  v-model="myRequirement.operator"/>

        <y-branch-select v-if="displayBranchSelect" class="pa-1" label="Agence" solo v-model="selectedBranch"/>
        <y-company-select v-else-if="displayCompanySelect" class="pa-1" label="Société" solo v-model="selectedCompany"/>
        <div class="v-card" v-else-if="valueType==='boolean'"><v-checkbox class="pa-1" :label="myRequirement.value" solo v-model="myRequirement.value" true-value="true" false-value="false"/></div>
        <v-text-field v-else-if="valueType==='number' && this.isSingleValue" type="number" :background-color="color" class="pa-1"  label="Valeur" solo v-model="myRequirement.value"/>
        <v-select v-else-if="valueType==='enum' && this.isSingleValue" :items="enumForCurrentKey" solo  v-model="myRequirement.value"/>
        <v-text-field v-else :background-color="color" class="pa-1" label="Valeur" solo v-model="myRequirement.value"/>

        <v-btn @click="$emit('delete', requirement)" class="ma-1 elevation-2" color="error" height="48"
               v-if="isRemovable"
               x-small>
            <v-icon>mdi-delete</v-icon>
        </v-btn>
    </div>
</template>

<script>
import {LicenseBusiness} from "@/business/license-business";

const EQ = {value: "EQUALS", text: "="}
const NOT_EQ = {value: "NOT_EQUALS", text: "!="}
const GT = {value: "GT", text: ">"}
const GT_OR_EQ = {value: "GT_OR_EQ", text: ">="}
const LT = {value: "LT", text: "<"}
const LT_OR_EQ = {value: "LT_OR_EQ", text: "<="}
const CONTAINS = {value: "CONTAINS", text: "contient"}
const NOT_CONTAINS = {value: "NOT_CONTAINS", text: "ne contient pas"}
const IN_CSV = {value: "IN_CSV", text: "dans le CSV"}
const NOT_IN_CSV = {value: "NOT_IN_CSV", text: "absent du CSV"}
const OPERATORS_ALL = [EQ, NOT_EQ, GT, GT_OR_EQ, LT, LT_OR_EQ, CONTAINS, NOT_CONTAINS, IN_CSV, NOT_IN_CSV];
const OPERATORS_BOOLEAN = [EQ, NOT_EQ]
const OPERATORS_STRING = [EQ, NOT_EQ, CONTAINS, NOT_CONTAINS, IN_CSV, NOT_IN_CSV]
const OPERATORS_NUMBER = [EQ, NOT_EQ, GT, GT_OR_EQ, LT, LT_OR_EQ, IN_CSV, NOT_IN_CSV]

	export default {
		name: "RequirementEditorPart",
		data() {
			return {
				myRequirement: {
          subject: "YFX_CONF_KEY",
          value: "",
          operator: "EQUALS",
          keyName: ""
        },
        confKeySelectItems: [],
        selectedBranch: null,
        selectedCompany: null,
				possibleSubjects: [
					{header: "Yodaforex"},
					{value: "YFX_CONF_KEY", text: "Clé conf"},
					{value: "YFX_LICENSE", text: "License"},
					{value: "YFX_COMPANY_UUID", text: "UUID de la société"},
					{value: "YFX_COMPANY_NAME", text: "Nom de la société"},
					{value: "YFX_BRANCH_UUID", text: "UUID de l'agence"},
					{value: "YFX_BRANCH_NAME", text: "Nom de l'agence"},
					// {header: "4D"},
					// {value: "FOURD_MAJOR_VERSION_NUMBER", text: "Numéro de version majeur de 4D"},
					// {value: "FOURD_R_VERSION_NUMBER", text: "Numéro de release R de 4D"},
					// {value: "FOURD_COMPLETE_VERSION_NUMBER", text: "Numéro de version complet de 4D"},
					// {value: "FOURD_BUILD_NUMBER", text: "Numéro de build de 4D"},
					// {header: "Logiciel"},
					// {value: "SW_IS_MACOS", text: "Utilise macOS"},
					// {value: "SW_OS_VERSION", text: "Version de l'OS"},
					// {value: "SW_IS_64BITS", text: "L'OS est 64 bits"},
					// {header: "Matériel"},
					// {value: "HW_RAM_AMOUNT", text: "Quantité de RAM (en Go)"},
					// {value: "HW_CPU_CLOCK", text: "Fréquence processeur (en GHz)"},
					// {value: "HW_CORE_COUNT", text: "Nombre de coeur du processeur"},
					// {value: "HW_IS_SSD", text: "Possède un SSD"}
				],
				subjectWithKeyName: [
					// "YFX_INFO_KEY",
					"YFX_CONF_KEY"
				],
				needsKeyName: false
			}
		},
		props: ['requirement', 'color', 'isRemovable'],
		mounted() {
			this.prepareRequirement(this.requirement);
			this.checkForKeyName();
			this.confKeySelectItems = LicenseBusiness.getKeysForSelect();
			if (this.displayBranchSelect) {
			  this.selectedBranch = this.requirement.value;
      } else if (this.displayCompanySelect) {
        this.selectedCompany = this.requirement.value;
      }
		},
    computed: {
		  isSingleValue() {
		    return this.myRequirement.operator === 'EQUALS' || this.myRequirement.operator === 'NOT_EQUALS';
      },
		  displayBranchSelect() {
		    return this.myRequirement.subject === 'YFX_BRANCH_UUID' && this.isSingleValue;
      },
      displayCompanySelect() {
        return this.myRequirement.subject === 'YFX_COMPANY_UUID' && this.isSingleValue;
      },
      valueType() {
		    if (this.needsKeyName && this.requirement.keyName && LicenseBusiness._keysDescriptions[this.requirement.keyName]) {
		      return LicenseBusiness._keysDescriptions[this.requirement.keyName].type;
        } else return null;
      },
      enumForCurrentKey() {
        if (this.needsKeyName && this.requirement.keyName && LicenseBusiness._keysDescriptions[this.requirement.keyName]) {
          return LicenseBusiness._keysDescriptions[this.requirement.keyName].values
        } else return null;
      },
      availableOperators() {
		    if (this.myRequirement.subject === 'YFX_LICENSE'
        || this.myRequirement.subject === 'YFX_COMPANY_UUID'
        || this.myRequirement.subject === 'YFX_COMPANY_NAME'
        || this.myRequirement.subject === 'YFX_BRANCH_UUID'
        || this.myRequirement.subject === 'YFX_BRANCH_NAME') return OPERATORS_STRING;

		    if (this.myRequirement.subject === 'YFX_CONF_KEY' && this.valueType) {
          if (this.valueType === 'email' || this.valueType === 'string' || this.valueType === 'enum') return OPERATORS_STRING;
          if (this.valueType === 'number') return OPERATORS_NUMBER;
          if (this.valueType === 'boolean') return OPERATORS_BOOLEAN;
        }

		    return OPERATORS_ALL;
      }
    },
		watch: {
			requirement: {
				deep: true,
				handler: function (newVal) {
					this.prepareRequirement(newVal);
				}
			},
			myRequirement: {
				deep: true,
				handler: function (newVal) {
					this.checkForKeyName();
					if (this.isValid()) {
						this.$emit("update", this.myRequirement, this.requirement);
					}
					if (newVal.subject !== 'YFX_CONF_KEY') newVal.keyName = null;
				}
			},
      "myRequirement.keyName": function(val, old) {
			  const oldType = LicenseBusiness._keysDescriptions[old] ? LicenseBusiness._keysDescriptions[old].type : null;
			  const newType = LicenseBusiness._keysDescriptions[val] ? LicenseBusiness._keysDescriptions[val].type : null;
			  if (oldType !== newType && newType === "boolean") this.myRequirement.value = "true";
			  if (oldType !== newType && newType === "string") this.myRequirement.value = "";
      },
      needsKeyName(val) {
			  if (!val) {
			    this.myRequirement.keyName = null;
        }
      },
      selectedBranch(newVal) {
			  this.myRequirement.value = newVal.uuid;
			},
      selectedCompany(newVal) {
			  this.myRequirement.value = newVal.uuid;
			}
		},
		methods: {
			prepareRequirement(requirement) {
				this.myRequirement = JSON.parse(JSON.stringify(requirement))
			},
			checkForKeyName() {
				this.needsKeyName = this.subjectWithKeyName.includes(this.myRequirement.subject);
			},
			isValid() {
				return !this.needsKeyName || !((!this.myRequirement.keyName || /^\s*$/.test(this.myRequirement.keyName)));
			}
		}
	}
</script>

<style scoped>
    .line {
        display: flex;
        flex-direction: row;
    }

</style>
