<template>
  <div>

    <div style="position: relative;">
      <v-btn small fab top right absolute color="primary" @click="prepareCreationForm">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <software-runtime-table ref="table" @selected="rowSelected"/>
    </div>
    <v-form v-if="current" class="mt-5">

      <v-card-title v-if="current.id">Modifier le runtime existant</v-card-title>
      <v-card-title v-else>Ajout un runtime</v-card-title>

        <v-text-field outlined label="Version du runtime" v-model="current.version" hint="Exemples :  “12”, “15 beta2”, “v19”, “v18 R6”" :rules="[rules.mandatory, rules.version]"/>
        <v-text-field outlined label="Nom" disabled v-model="current.label" :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Identifiant" v-model="current.identifier" disabled/>

        <v-card-subtitle>macOS (DMG full provenant de 4D uniquement)</v-card-subtitle>
        <v-text-field outlined label="URL" v-model="current.clientMacOsRuntime.remoteUrl" :rules="[rules.mandatory, rules.url]"/>
        <v-text-field outlined label="Nom du volume DMG" v-model="current.clientMacOsRuntime.config.volumeName" :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Nom dossier dans le DMG" v-model="current.clientMacOsRuntime.config.folderName" :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Version d'OS minimum" v-model="current.clientMacOsRuntime.minimumOsVersion" :rules="[rules.mandatory]"/>
        <v-text-field outlined label="Packaging" v-model="current.clientMacOsRuntime.packaging" disabled/>


<!--        <v-card-subtitle>Windows</v-card-subtitle>-->
<!--        <v-text-field outlined label="URL" v-model="current.clientWindowsRuntime.remoteUrl" :rules="[rules.mandatory, rules.url]"/>-->
<!--        <v-text-field outlined label="Nom du volume DMG" v-model="current.clientWindowsRuntime.config.volumeName" :rules="[rules.mandatory]"/>-->
<!--        <v-text-field outlined label="Nom dossier dans le DMG" v-model="current.clientWindowsRuntime.config.folderName" :rules="[rules.mandatory]"/>-->
<!--        <v-text-field outlined label="Version d'OS minimum" v-model="current.clientWindowsRuntime.minimumOsVersion" :rules="[rules.mandatory]"/>-->
<!--        <v-text-field outlined label="Packaging" v-model="current.clientWindowsRuntime.packaging" disabled/>-->


      <div class="d-flex">
        <v-spacer/>
        <v-btn text color="secondary" @click="cancel">Annuler</v-btn>
        <v-btn text color="primary" @click="save" :loading="loadingSaving"><span
            v-if="current.id">Mettre à jour</span><span v-else>Créer</span></v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import SoftwareRuntimeTable from "@/components/SoftwareRuntimeTable";

const EMPTY_FORM = {
	label: "",
	identifier: "",
	version: "",
	clientMacOsRuntime: {
	  remoteUrl: "",
	  minimumOsVersion: "10.14.6",
	  packaging: "DMG",
	  config: {
		  volumeName: "",
		  folderName: ""
	  }
  },
	clientWindowsRuntime: {
	  remoteUrl: "",
	  minimumOsVersion: "10.14.6",
	  packaging: "ZIP",
	  config: {
		  volumeName: "",
		  folderName: ""
	  }
  }
}

export default {
  name: "SoftwareRuntimeManager",
  components: {SoftwareRuntimeTable},
  data: () => ({
    current: null,
    loadingSaving: false,
    error: false,
    rules: {
        mandatory: (v) => !!v || 'Champ obligatoire',
        version: (v) => /^v?(\d)*( \w+)?$/g.test(v) || 'Version mal formatée', // Autorise "v123" "v123 quelquechose"
        url: (v)=> /^http(s)?:\/\/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?$/g.test(v) || 'URL mal formatée'
    }
  }),
  watch: {
    "current.version"(v) {
      if (v && this.rules.version(v) === true) {
        let withV = v.indexOf("v") === 0 ? v : "v"+v;
        let formatted = withV.toLowerCase().replaceAll(' ', '-');
        this.current.identifier = `4d-${formatted}`
        if (!this.current.id) {
          this.current.label = `4D ${withV}`
          this.current.clientMacOsRuntime.config.volumeName = `4D ${withV}`;
          this.current.clientMacOsRuntime.config.folderName = `4D ${withV}`;
        }
      } else {
        this.current.identifier = "";
        if (!this.current.id) {
          this.current.label = "";
          this.current.clientWindowsRuntime.config.volumeName = "";
          this.current.clientWindowsRuntime.config.folderName = "";
        }
      }
    },
  },
  methods: {
    rowSelected(row) {
      if (!row.config) {
        row.config = EMPTY_FORM.config;
      }
      this.current = Object.assign({}, row);
    },
    prepareCreationForm() {
      this.current = Object.assign({}, EMPTY_FORM);
    },
    cancel() {
      this.current = null;
    },
    save() {
      this.loadingSaving = true;
      this.error = false;
      if (this.current.version.indexOf('v') !== 0) this.current.version = "v" + this.current.version;
      if (this.current.id) {
        this.$http.put(this.$config.apiUrl + "/admin/runtimes/" + this.current.id, this.current)
            .then(() => {
              this.current = null;
              this.refreshTable();
            })
            .catch(() => this.error = false).finally(() => this.loadingSaving = false);
      } else {
        this.$http.post(this.$config.apiUrl + "/admin/runtimes", this.current)
            .then(() => {
              this.current = null;
              this.refreshTable();
            })
            .catch(() => this.error = false).finally(() => this.loadingSaving = false);
      }
    },
    refreshTable() {
      this.$refs.table.refresh();
    }

  }
}
</script>

<style scoped>

</style>
