<template>
    <v-container>
        <v-dialog max-width="900px" persistent v-model="versionCreationFormDialog">
            <v-card>
                <software-version-form @saved="handleChange" @undo="versionCreationFormDialog = false"/>
            </v-card>
        </v-dialog>
        <v-row class="mt-5">
            <v-col cols="12" lg="6">
              <div style="position: relative;">
              <v-btn small fab absolute top right color="primary" @click="versionCreationFormDialog=true"><v-icon>mdi-plus</v-icon></v-btn>
              <v-card>
                <software-version-table @selected="versionHasBeenSelected" ref="table"/>
              </v-card>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
                <software-version-form :version="selectedVersion" @saved="handleChange"
                                       @undo="selectedVersion=null"
                                       @deleted="handleChange" v-if="selectedVersion"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SoftwareVersionTable from "../components/SoftwareVersionTable";
import SoftwareVersionForm from "../components/SoftwareVersionForm";

export default {
		name: "SoftwareVersionHome",
		components: {SoftwareVersionForm, SoftwareVersionTable},
		data() {
			return {
				versionCreationFormDialog: false,
				selectedVersion: null
			}
		},
		methods: {
			versionHasBeenSelected(version) {
				this.selectedVersion = version;
			},
            handleChange(newSelection) {
				this.versionCreationFormDialog = false;
				this.$refs.table.refresh();
				this.selectedVersion = newSelection;

			}
		}
	}
</script>

<style scoped>
    .container {
        max-width: 1700px;
    }
</style>
