var merge = require('lodash/merge')
var prodEnv = require('./prod.env')

module.exports = merge(prodEnv, {
	env: 'development',
	apiUrl: 'http://localhost:8080/v1', // Local seulement,
	authConfig: {
		devMode: true
	}
})
