<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        class="elevation-1"
        @click:row="rowClicked"
    >
    </v-data-table>
    <v-snackbar :bottom="true" :timeout="-1" color="error" v-model="error">
      Une erreur est survenue lors du chargement de la liste des runtimes.
      <v-btn @click="refresh" dark text>Réessayer</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "SoftwareRuntimeTable",
  data() {
    return {
      items: [],
      totalItems: 0,
      headers: [
        {text: 'Nom', value: 'label'},
        {text: 'Utilisation', value: 'versionCount'}
      ],
      loading: true,
      error: false,
      options: {
        sortBy: ['label']
      }
    }
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.loading = true;
      this.error = false;

      let url = this.$config.apiUrl + "/admin/runtimes";
      this.$http.get(url)
          .then(r => {
            this.items = r.data;
          })
          .catch(e => this.error = true)
          .finally(() => this.loading = false);

    },
    rowClicked(item) {
      this.$emit('selected', item);
    }
  }
}
</script>

<style scoped>

</style>
