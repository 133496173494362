<template>
    <v-app>
        <v-app-bar app color="blue-grey lighten-4">
            <v-toolbar-title class="headline text-uppercase">
                <span>Yodaforex Codepatch</span>
            </v-toolbar-title>
            <v-spacer/>
            <y-auth-button v-model="isAuth"/>
        </v-app-bar>

        <v-main>
            <router-view v-if="isAuth"/>
            <v-container v-else>
                <div class="d-flex justify-center">
                    <v-progress-linear indeterminate/>
                </div>
            </v-container>
        </v-main>

    </v-app>
</template>

<script>

	export default {
		name: 'App',
		data() {
			return {
				isAuth: false
			}
		}
	};
</script>

