<template>
    <v-card>
        <v-card-title>
            <pre v-if="myVersion">{{formattedVersion}}</pre>
        </v-card-title>
        <v-tabs
                :centered="true"
                class="elevation-2"
                dark
                v-model="tab"
        >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#general">General</v-tab>
            <v-tab href="#runtimes">Runtime</v-tab>
            <v-tab href="#requirements" v-if="myVersion && myVersion.id">Conditions</v-tab>
            <v-tab href="#binary" v-if="myVersion && myVersion.id">Fichier</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-form>
                <v-tab-item value="general">
                    <v-container class="pa-4" v-if="myVersion">
                        <v-row align="center" justify="center">
                            <v-col cols="12" sm="3">
                                <v-text-field label="Majeur" type="number" v-model="myVersion.major"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field label="Mineur" type="number" v-model="myVersion.minor"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field label="Fix" type="number" v-model="myVersion.fix"/>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <v-text-field label="Suffixe beta" v-model="myVersion.suffix"/>
                            </v-col>
                        </v-row>
                        <!--Seulement si c'est un update, car on doit sauvegarder la version avant de pouvoir y attacher un fichier, et donc la rendre publique-->
                        <v-row v-if="myVersion.id">
                            <v-col cols="12" sm="6">
                                <v-select :disabled="!myVersion.binaryFileName" label="Mise à disposition" :items="versionAvailabilitySelectItems" v-model="myVersion.availability"/>
                            </v-col>
                            <v-col cols="12" sm="6" v-if="!myVersion.binaryFileName">
                                <v-alert border="left" elevation="1" light type="warning">Une version doit
                                    avoir un fichier attaché pour pouvoir être rendue publique.
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-switch label="Version de passage obligatoire" v-model="myVersion.isMandatoryStep"/>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-alert :type="myVersion.isMandatoryStep ? 'warning' : 'info'"
                                         border="left" elevation="1" light outlined>
                                    <span v-if="myVersion.isMandatoryStep">Un passage par cette version est obligatoire
                                        avant de pouvoir installer une version plus récente.</span>
                                    <span v-else>Cette version peut être ignorée si une version plus récente est disponible.</span>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-tab-item>
                <v-tab-item value="runtimes">
                  <v-card-text>
                    <v-card-subtitle v-if="!myVersion.runtime">Aucun runtime n'est rattaché à cette version</v-card-subtitle>
                    <v-select :loading="loadingRuntimes" v-model="myVersion.runtime" :items="availableRuntimes" item-text="label" return-object item-value="id"></v-select>
                    <v-btn text @click="showRuntimeDialog=true">Gérer les runtimes</v-btn>
                  </v-card-text>
                </v-tab-item>
                <v-tab-item value="requirements">
                    <div class="pa-2">
                        <requirements-label
                                :requirements="myVersion.requirements"
                                v-if="myVersion && (myVersion.requirements.requirements.length + myVersion.requirements.requirementGroups.length) > 0"/>
                        <span v-else>Cette mise à jour sera proposée sans condition</span>
                    </div>
                    <div class="pa-4">
                        <v-card class="mt-4" v-if="myVersion">
                            <requirements-editor :requirements="myVersion.requirements"
                                                 @delete="requirementsHasBeenDeleted"
                                                 @update="requirementsHasBeenUpdated"/>
                        </v-card>
                        <v-dialog fullscreen hide-overlay transition="dialog-bottom-transition"
                                  v-if="myVersion && (myVersion.requirements.requirements.length + myVersion.requirements.requirementGroups.length) > 0"
                                  v-model="simualationDialog">
                            <template v-slot:activator="{ on }">
                                <v-btn color="primary" text v-on="on">Simulateur</v-btn>
                            </template>
                            <v-card>
                                <v-toolbar color="primary" dark>
                                    <v-btn @click="simualationDialog = false" dark icon>
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Simulation de conditions</v-toolbar-title>
                                    <v-spacer/>
                                    <v-btn @click="$refs.simuTable.refresh()" color="warning" light>Lancer la
                                        simulation
                                    </v-btn>
                                </v-toolbar>
                                <v-card class="pa-3">
                                    <requirements-label :requirements="myVersion.requirements"/>
                                </v-card>

                                <requirement-simulation-table :hide-refresh-button="true"
                                                              :requirements="myVersion.requirements"
                                                              ref="simuTable"/>
                            </v-card>
                        </v-dialog>

                    </div>
                </v-tab-item>
                <v-tab-item v-if="myVersion && myVersion.id" value="binary">
                    <div class="pa-4">
                        <binary-uploader :version-id="myVersion.id" @uploaded="onFileUploaded"
                                         v-if="!myVersion.binaryFileName"/>
                        <v-container v-else>
                            <v-row>
                                <v-text-field :value="convertToHumanReadableSize(myVersion.binaryLength)"
                                              label="Taille du fichier"
                                              readonly></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field :value="myVersion.binaryChecksum" label="Checksum SHA-1"
                                              readonly></v-text-field>
                            </v-row>
                            <v-row>
                                <v-text-field :value="formatDate(myVersion.binaryUploadDate)" label="Date d'ajout"
                                              readonly></v-text-field>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn @click="downloadAttachedBinary" color="success">
                                        Télécharger le fichier attaché
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn :loading="deletingFile" @click="deleteAttachedBinary" color="error">
                                        Supprimer le fichier attaché
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn :loading="generatingOneTimeLink" @click="generateOneTimeLink"
                                           color="primary">
                                        Générer un lien temporaire
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col v-if="oneTimeLink">
                                    <v-text-field :value="oneTimeLink" label="Lien de téléchargement temporaire"
                                                  outlined
                                                  readonly/>
                                </v-col>
                            </v-row>
                        </v-container>

                    </div>
                </v-tab-item>
            </v-form>
        </v-tabs-items>
        <v-divider class="mt-12"/>

        <div class="d-flex">
            <v-btn :loading="deleting" color="error" text @click="deleteDialog=true">Supprimer la version</v-btn>
            <v-spacer/>
            <v-btn @click="undo" text>
                Annuler
            </v-btn>
            <v-btn :disabled="!hasChanged" :loading="saving" @click="save" color="primary" text>
                Sauvegarder
            </v-btn>
            <!--Dialogue de confirmation de suppression du groupe-->
            <v-dialog v-if="myVersion && myVersion.id" v-model="deleteDialog" width="500">
                <v-card>
                    <v-card-title class="headline error" primary-title>Êtes-vous sûr ?</v-card-title>
                    <v-card-text>Supprimer la version et l'intégralité de son contenu ? (Les fichiers annexes ne seront pas supprimés et continueront d'être utilisables pour les autres versions)</v-card-text>
                    <v-card-actions>
                        <v-btn @click="deleteDialog = false">Annuler</v-btn>
                        <v-btn @click="deleteVersion" color="error">Supprimer la version</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

          <v-dialog v-model="showRuntimeDialog" max-width="700">
            <v-card>
              <v-card-title>Runtimes</v-card-title>
              <v-card-text><software-runtime-manager/></v-card-text>
              <v-card-actions>
                <v-spacer/>
                <v-btn text @click="showRuntimeDialog=false">Terminer</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </div>

        <v-snackbar top color="error" v-model="error">
            Une erreur est survenue.
            <v-btn @click="error=false" dark text>Fermer</v-btn>
        </v-snackbar>

        <v-snackbar top color="success" v-model="oneTimeLinkCopiedAlert">
            Lien copié dans le presse-papier !
        </v-snackbar>

    </v-card>
</template>

<script>
import RequirementsLabel from "./RequirementsLabel";
import RequirementsEditor from "./RequirementsEditor";
import BinaryUploader from "./BinaryUploader";
import Utils from "../utils"
import RequirementSimulationTable from "./RequirementSimulationTable";
import copy from "copy-to-clipboard"
import SoftwareRuntimeManager from "@/components/SoftwareRuntimeManager";

export default {
		name: "SoftwareVersionForm",
		components: {
      SoftwareRuntimeManager,
      RequirementSimulationTable, BinaryUploader, RequirementsEditor, RequirementsLabel},
		data() {
			return {
				myVersion: null,
				formattedVersion: null,
				tab: null,
				hasChanged: false,
				fileUploadHeaders: [],
				deletingFile: false,
				saving: false,
				deleting: false,
				generatingOneTimeLink: false,
				oneTimeLinkCopiedAlert: false,
				oneTimeLink: null,
				deleteDialog: false,
				error: false,
				simualationDialog: false,
        availableRuntimes: [],
        loadingRuntimes: false,
        showRuntimeDialog: false,
        versionAvailabilitySelectItems: [{text:"Publique", value: "PUBLIC"},{text:"Interne", value: "INTERNAL"},{text:"Non disponible", value: "NOT_AVAILABLE"}]
			}
		},
		mounted() {
			this.prepare(this.version);
			this.fetchAvailableRuntimes();
			this.fileUploadHeaders = this.$http.defaults.headers.common;
		},
		filters: {
			humanReadableSize(size) {
				return this.convertToHumanReadableSize(size);
			}
		},
		props: ['version'],
		watch: {
      showRuntimeDialog(newVal) {
        if (!newVal) this.fetchAvailableRuntimes();
      },
			version: {
				deep: true,
				handler: function (newVal) {
					this.prepare(newVal);
					this.oneTimeLink = null;
				}
			},
			myVersion: {
				deep: true,
				handler: function (newVal) {
					this.checkChange(newVal);
				}
			},
			oneTimeLink(newVal) {
				if (newVal) {
					if (copy(newVal)) {
						this.oneTimeLinkCopiedAlert = true;
					} else {

					}
				}
			}
		},
		methods: {
			prepare(newVal) {
				if (newVal == null) {
					// Creation
					this.myVersion = {
						requirements: {
							requirements: [],
							requirementGroups: [],
							type: "AND"
						},
						major: 1,
						minor: 0,
						fix: 0,
            availability: 'NOT_AVAILABLE',
						isMandatoryStep: false
					};
				} else {
					this.myVersion = Object.assign({}, newVal);
				}
			},
			requirementsHasBeenUpdated(newRequirements) {
				this.myVersion.requirements = newRequirements;
			},
			requirementsHasBeenDeleted() {
				this.myVersion.requirements = {
					requirements: [],
					requirementGroups: []
				};
			},
			checkChange(newVal) {
				this.hasChanged = JSON.stringify(newVal) !== JSON.stringify(this.version);
				this.formattedVersion = newVal.major + "." + newVal.minor + "." + newVal.fix;
				if (newVal.suffix) {
					this.formattedVersion += "-" + newVal.suffix;
				}
			},
			save() {
				this.saving = true;
				let req;

        let payload = {
            major: this.myVersion.major,
            minor: this.myVersion.minor,
            fix: this.myVersion.fix,
            suffix: this.myVersion.suffix,
            availability: this.myVersion.availability,
            requirements: this.myVersion.requirements,
            isMandatoryStep: this.myVersion.isMandatoryStep
        }

				if (this.myVersion.id) {
					// update
					req = this.$http.put(this.$config.apiUrl + "/admin/versions/" + this.myVersion.id, this.myVersion)
				} else {
					// create
					req = this.$http.post(this.$config.apiUrl + "/admin/versions", this.myVersion)
				}

				req
					.then(r => {
						this.$emit("saved", r.data)
					})
					.catch(e => this.error = true)
					.finally(() => this.saving = false)
			},
			undo() {
				this.$emit('undo')
			},
			deleteVersion() {
				this.deleting = true;
				this.$http.delete(this.$config.apiUrl + "/admin/versions/" + this.myVersion.id, this.myVersion)
					.then(r => {
						this.$emit("deleted")
					})
					.catch(e => this.error = true)
					.finally(() => {
						this.deleteDialog = false;
						this.deleting = false;
					})
			},
			onFileUploaded(newVersion) {
				this.myVersion = newVersion;
				this.$emit("changed", this.myVersion);
			},
			deleteAttachedBinary() {
				this.deletingFile = true;
				this.$http.delete(this.$config.apiUrl + "/admin/versions/" + this.version.id + "/file")
					.then(r => {
						this.myVersion = r.data;
						this.$emit("changed", this.myVersion);
					})
					.catch(e => {
						this.error = true;
					})
					.finally(() => this.deletingFile = false)

			},
			downloadAttachedBinary() {
				this.loading = true;
				this.$http({
					url: this.$config.apiUrl + "/admin/versions/" + this.myVersion.id + "/file",
					method: 'GET',
					// headers: {'Accept': 'text/csv'},
					responseType: 'blob', // important
				})
					.then((response) => {
						// Downloading the file
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', this.myVersion.version + '.zip');
						document.body.appendChild(link);
						link.click();
					})
					.catch(e => this.downloadError = true)
					.finally(() => this.loading = false);
			},
			generateOneTimeLink() {
				this.generatingOneTimeLink = true;
				// Si l'URL d'API est relative, on la reconstruit à partir de l'URL de notre appli JS. Sinon, on l'utilise telle quelle.
				let baseApiUrl = this.$config.apiUrl.startsWith("http") ? this.$config.apiUrl : (window.location.origin + "/v1");
				this.$http.get(this.$config.apiUrl + "/admin/versions/" + this.version.id + "/manualdownloadtoken")
					.then(r => {
						this.oneTimeLink = baseApiUrl + "/updates/download?token=" + r.data;
					})
					.catch(e => this.error = true)
					.finally(() => this.generatingOneTimeLink = false)
			},
			convertToHumanReadableSize(bytes) {
				return Utils.convertToHumanReadableSize(bytes);
			},
			formatDate(date) {
				return this.$moment(date).format('YYYY/MM/DD HH:mm');
			},
      fetchAvailableRuntimes() {
        this.loadingRuntimes=true;
        this.error=false;
        this.$http.get(this.$config.apiUrl + "/admin/runtimes")
            .then(r => this.availableRuntimes = r.data)
            .catch(() => this.error = true)
            .finally(() => this.loadingRuntimes = false)
      }
		}

	}
</script>

<style scoped>

</style>
