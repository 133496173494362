<template>
    <v-card :color="depth | colorize">

        <!-- Menu pour rajouter des conditions / groupe ou supprimer un groupe (si ce n'est pas le groupe racine-->
        <v-card-actions>
            <v-menu offset-y>
                <template v-slot:activator="{ on }">
                    <v-btn absolute color="pink" dark fab right small top v-on="on">
                        <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item-group>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title @click="addRequirement">
                                Ajouter une condition
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-plus</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title @click="addRequirementGroup" block>
                                Ajouter un groupe de conditions
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="error">
                            <v-list-item-icon>
                                <v-icon color="error">mdi-delete</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title @click="deleteDialog=true" block color="error">
                                Supprimer le groupe de conditions
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-card-actions>

        <v-container>

            <!--Menu pour choisir entre AND et OR, affiché seulement s'il y a plusieurs conditions-->
            <v-row dense
                   v-if="hasMoreThanOneCondition">
                <v-col>
                    <v-select
                            :background-color="depth+1 | colorize"
                            :items="availableOperators" solo v-model="myRequirements.type"/>
                </v-col>
            </v-row>

            <!--Liste des conditions-->
            <div v-for="(r,idx) in myRequirements.requirements" :key="'req-'+idx">
              <v-row dense>
                <v-col>
                    <requirement-editor-part
                            :color="(depth +1) | colorize"
                            :is-removable="myRequirements.requirements.length > 1"
                            :requirement="r"
                            @delete="deleteRequirement(r,idx)"
                            @update="requirementHasBeenUpdated"
                    />
                </v-col>
              </v-row>
            </div>

            <!--Liste des groupes de conditions-->
            <div v-for="(rg,idx) in myRequirements.requirementGroups" :key="'grp-'+idx">
              <v-row dense>
                <v-col>
                    <requirements-editor :group-depth="depth + 1" :requirements="rg"
                                         @delete="deleteRequirementGroup(rg, idx)"
                                         @update="requirementGroupHasBeenUpdated"/>
                </v-col>
              </v-row>
            </div>

            <!--Si nous sommes dans le groupe racine, le bouton sauvegarde-->
            <v-row dense v-if="depth === 0 && (myRequirements.requirementGroups.length + myRequirements.requirements.length) === 0">
                <v-col>
                    Pour ajouter une condition, cliquer sur l'icône <v-icon>mdi-dots-horizontal</v-icon>
                </v-col>
            </v-row>

        </v-container>

        <!--Dialogue de confirmation de suppression du groupe-->
        <v-dialog v-model="deleteDialog" width="500">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>Êtes-vous sûr ?</v-card-title>
                <v-card-text>Supprimer le groupe de conditions et l'intégralité de son contenu ?</v-card-text>
                <v-card-actions>
                    <v-btn @click="deleteDialog = false">Annuler</v-btn>
                    <v-btn @click="deleteConfirmHasBeenClicked" color="error">Supprimer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-card>
</template>

<script>
import RequirementEditorPart from "./RequirementEditorPart";

const EMPTY_REQUIREMENT = {
  subject: "YFX_CONF_KEY",
  value: "",
  operator: "EQUALS",
  keyName: ""
}
const EMPTY_REQUIREMENT_GROUP = {
  requirements: [],
  type: 'AND'
}

export default {
		name: "RequirementsEditor",
		components: {RequirementEditorPart},
		data() {
			return {
				deleteDialog: false,
				myRequirements: {
					type: null,
					requirements: [],
					requirementGroups: [],
				},
				requirementJSON: null,
				hasChanged: false,
				depth: 0,
				availableOperators: [
					{value: "AND", text: "Toutes les conditions et groupes de conditions"},
					{value: "OR", text: "Au moins une des conditions ou groupe de conditions"}
				]
			}
		},
		filters: {
			colorize(depth) {
				// Retourne une couleur material différente pour chaque profondeur de groupe de condition
				// Voir https://vuetifyjs.com/en/styles/colors#
				let colorsForDepth = ['green', 'amber', 'purple', 'lime', 'indigo', 'red', 'blue'];
				let maxLighteningIndice = 4;
				let effectiveDepth = depth % (colorsForDepth.length * maxLighteningIndice); // On revient au début si on dépasse le nombre total de couleurs possibles
				let colorIdx = effectiveDepth % colorsForDepth.length;
				let colorLightening = maxLighteningIndice - Math.floor(effectiveDepth / colorsForDepth.length);
				return colorsForDepth[colorIdx] + ' lighten-' + (colorLightening)
			}
		},
		props: ['requirements', 'groupDepth'],
		mounted() {
			this.prepareDepth(this.groupDepth);
			this.prepareRequirements(this.requirements);
		},
		watch: {
			groupDepth(newVal) {
				this.prepareDepth(newVal);
			},
			requirements: {
				deep: true,
				handler: function (newVal) {
					this.prepareRequirements(newVal);
				}
			},
			myRequirements: {
				deep: true,
				handler: function (newVal) {
					this.checkChange(newVal);
				}
			}
		},
		computed: {
			hasMoreThanOneCondition() {
				return this.myRequirements.requirements && this.myRequirements.requirementGroups && (this.myRequirements.requirements.length + this.myRequirements.requirementGroups.length > 1)
			}
		},
		methods: {
			prepareRequirements(requirements) {
				this.requirementJSON = JSON.stringify(requirements); // On garde une copie du JSON de l'objet passé en props pour le comparer à notre version de travail dans hasChanged
				let myRequirements = JSON.parse(this.requirementJSON); // On parse le qu'on vient de générer JSON pour faire une deep copy propre

				if (!myRequirements.requirementGroups || !Array.isArray(myRequirements.requirementGroups)) {
					myRequirements.requirementGroups = [];
					// Remplacer "null" par un tableau vide ne devrait pas être considéré comme une modif, on met à jour le JSON de référence pour prendre en compte ce changement (sinon hasChanged retournera toujours "true" à cause de ce tableau vide qui remplace le "null")
					this.requirementJSON = JSON.stringify(myRequirements);
				}
				if (!myRequirements.requirements || !Array.isArray(myRequirements.requirements)) {
					myRequirements.requirements = [];
					myRequirements.requirements.push(Object.assign({}, EMPTY_REQUIREMENT))
					// Idem condition du dessus. Oui il est possible qu'on le fasse 2 fois. Tant pis.
					this.requirementJSON = JSON.stringify(myRequirements);
				}

				this.myRequirements = myRequirements;

			},
			requirementHasBeenUpdated(newRequirement, oldRequirement) {
				let oldIdx = this.myRequirements.requirements.indexOf(oldRequirement);
				this.myRequirements.requirements[oldIdx] = newRequirement; // monarray[i]=x  n'est pas réactif, mais ce n'est pas grave, l'objet enfant est quand même à jour puisqu'il a subit la modif de l'utilisateur
				this.checkChange(this.myRequirements);
			},
			requirementGroupHasBeenUpdated(newRequirementGroup, oldRequirementGroup) {
				let oldIdx = this.myRequirements.requirementGroups.indexOf(oldRequirementGroup);
				// this.myRequirements.requirementGroups.splice(oldIdx, 1, newRequirevementGroup);
				this.myRequirements.requirementGroups[oldIdx] = newRequirementGroup; // monarray[i]=x  n'est pas réactif, mais ce n'est pas grave, l'objet enfant est quand même à jour puisqu'il a subit la modif de l'utilisateur
				this.checkChange(this.myRequirements);
			},
			saveHasBeenClicked() {
				this.$emit("save", this.myRequirements);
			},
			checkChange(newVal) {
				let newValJson = JSON.stringify(newVal);
				this.hasChanged = newValJson !== this.requirementJSON;
				if (this.hasChanged) {
					this.$emit('update', this.myRequirements, this.requirements)
				}
			},
			prepareDepth(depth) {
				this.depth = depth ? depth : 0;
			},
			addRequirementGroup() {
				if (!Array.isArray(this.myRequirements.requirementGroups)) {
					this.myRequirements.requirementGroups = [];
					console.log("arh")
				}
				this.myRequirements.requirementGroups.push(Object.assign({}, EMPTY_REQUIREMENT_GROUP));
			},
			addRequirement() {
				if (!Array.isArray(this.myRequirements.requirements)) {
					this.myRequirements.requirements = [];
				}
				this.myRequirements.requirements.push(Object.assign({},EMPTY_REQUIREMENT));
			},
			deleteRequirement(requirement, index) {
				this.myRequirements.requirements.splice(index, 1)
			},
			deleteRequirementGroup(requirementGroup, index) {
				this.myRequirements.requirementGroups.splice(index, 1)
			},
			deleteConfirmHasBeenClicked() {
				this.deleteDialog = false;
				this.$emit('delete', this.requirements)
			}
		}
	}
</script>

<style scoped>

</style>
