<template>
    <div>
        <div>
            <v-file-input accept="application/zip" :disabled="uploading" show-size solo v-model="filePath"
                          label="Sélectionner un fichier pour cette version"/>
            <v-btn :disabled="uploading || filePath==null" @click="startUpload" color="primary">Envoyer</v-btn>
        </div>
        <div class="pa-4" v-if="uploading">
            <v-progress-linear :indeterminate="finalizing" v-model="progress"/>
            <pre>{{bytesSent | formatSize}}/{{bytesTotal | formatSize}}</pre>
            <span v-if="finalizing">Envoi terminé. Finalisation côté serveur...</span>
        </div>
        <v-snackbar :bottom="true" color="error" v-model="error">
            Une erreur est survenue lors de l'envoi du fichier.
            <v-btn @click="error=false" dark text>Fermer</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import tus from "tus-js-client"
import Utils from "../utils"

let upload;

	export default {
		name: "BinaryUploader",
		data() {
			return {
				error: false,
				filePath: null,
				uploading: false,
				bytesSent: 0,
				bytesTotal: 0,
				progress: 0,
				finalizing: false
			}
		},
		props: ['versionId'],
		filters: {
			formatSize(size) {
				return Utils.convertToHumanReadableSize(size);
			}
		},
		methods: {
			startUpload() {
				let url = this.$config.apiUrl + "/admin/versions/" + this.versionId + "/file/parts";
				let headers = Object.assign({}, this.$http.defaults.headers.common);
				this.uploading = true;
				upload = new tus.Upload(this.filePath, {
					endpoint: url,
					retryDelays: [0, 3000, 5000, 10000, 20000],
					headers: headers,
					chuckSize: 1024,
					resume: true,
					onSuccess: this.onUploadSuccess,
					onError: this.onUploadError,
					onProgress: this.onUploadProgress
				});
				upload.start();
			},
			onUploadSuccess() {
				this.finalizing = true;
				let payload = {url: upload.url, originalFileName: this.filePath.name, mimeType: this.filePath.type};
				this.$http.post(this.$config.apiUrl + "/admin/versions/" + this.versionId + "/file/uploadendednotif", payload)
					.then((r) => {
						this.$emit("uploaded", r.data);
					})
					.catch((e) => {
						this.error = true;
					})
					.finally(() => {
						this.uploading = false
						this.finalizing = false
					})
			},
			onUploadError(e) {
				this.error = true;
				this.uploading = false;
			},
			onUploadProgress(bytesSent, bytesTotal) {
				this.bytesSent = bytesSent;
				this.bytesTotal = bytesTotal;
				this.progress = bytesSent / bytesTotal * 100;
			}
		}
	}
</script>

<style scoped>

</style>
