<template>
    <div>
        <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :server-items-length="totalItems"
                @click:row="rowClicked"
        >
            <template v-slot:[`item.requirements`]="{ item }">
              <v-tooltip bottom color="grey lighten-3">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" :color="hasRequirements(item) ? 'warning' : 'success'"
                          :outlined="!hasRequirements(item)" dark>
                    {{ hasRequirements(item) ? "Oui" : "Non" }}
                  </v-chip>
                </template>
                <div>
                  <requirements-label v-if="hasRequirements(item)" :requirements="item.requirements"/>
                  <span v-else>Aucune condition</span>
                </div>
              </v-tooltip>
            </template>
            <template v-slot:[`item.isMandatoryStep`]="{ item }">
                <v-chip :color="item.isMandatoryStep ? 'info' : 'success'" :outlined="!item.isMandatoryStep" dark>
                    {{ item.isMandatoryStep ? "Oui" : "Non" }}
                </v-chip>
            </template>
            <template v-slot:[`item.isPublic`]="{ item }">
                <v-chip :color="item.binaryFileName ? (item.availability === 'PUBLIC' ? 'success' : (item.availability === 'INTERNAL' ? 'lime' : 'grey')) : 'warning'" dark :outlined="!item.binaryFileName">
                    <span v-if="item.binaryFileName">{{ item.availability === 'PUBLIC' ? 'Oui' : (item.availability === 'INTERNAL' ? 'Interne' : 'Non') }}</span>
                    <span v-else>Fichier manquant</span>
                </v-chip>
            </template>
            <template v-slot:[`item.creationDate`]="{ item }">
                {{item.creationDate | moment('YYYY/MM/DD HH:mm')}}
            </template>
            <template v-slot:[`item.runtime`]="{ item }">
                <v-chip v-if="!item.runtime" color="warning" outlined>Manquant</v-chip>
                <v-chip v-else color="success" outlined>{{item.runtime.label}}</v-chip>
            </template>
        </v-data-table>
        <v-snackbar :bottom="true" :timeout="-1" color="error" v-model="error">
            Une erreur est survenue lors du chargement de la liste de versions.
            <v-btn @click="refresh" dark text>Réessayer</v-btn>
        </v-snackbar>

    </div>
</template>

<script>

import RequirementsLabel from "@/components/RequirementsLabel";

export default {
		name: "SoftwareVersionTable",
    components: {RequirementsLabel},
    data() {
			return {
				items: [],
				totalItems: 0,
				headers: [
					{text: 'Version', value: 'version'},
					{text: 'Conditions', value: 'requirements'},
					{text: 'Date de création', value: 'creationDate'},
					{text: 'Disponibilité', value: 'isPublic'},
					{text: 'Étape oblig.', value: 'isMandatoryStep'},
					{text: 'Runtime', value: 'runtime'}
				],
				loading: true,
				error: false,
				options: {}
			}
		},
		watch: {
			options: {
				handler() {
					this.refresh();
				},
				deep: true,
			},
		},
		mounted() {
			this.refresh();
		},
		methods: {
			refresh() {
				const {sortBy, sortDesc, page, itemsPerPage} = this.options;
				this.loading = true;
				this.error = false;

				let url = this.$config.apiUrl + "/admin/versions?page=" + (page - 1) + "&size=" + itemsPerPage;
				if (sortBy.length) {
					url += "&sort=" + sortBy[0] + "," + (sortDesc[0] ? 'desc' : 'asc');
				}
				this.$http.get(url)
					.then(r => {
						this.items = r.data.content;
						this.totalItems = r.data.totalElements;
					})
					.catch(e => this.error = true)
					.finally(() => this.loading = false);

			},
			hasRequirements(item) {
				return (item.requirements && item.requirements.requirements.length > 0)
			},
			rowClicked(item) {
				this.$emit('selected', item);
            }
		}

	}
</script>

<style scoped>

</style>
