<template>
    <div>
        <v-data-table :headers="headers" :items="items" :items-per-page="-1" :loading="loading" :options.sync="options">
            <template v-slot:[`item.eligible`]="{ item }">
                <v-chip :color="item.eligible ? 'success' : 'warning'" dark>
                    {{item.eligible ? "Oui" : "Non"}}
                </v-chip>
            </template>
            <template v-slot:[`item.license`]="{ item }">
                <pre>{{item.license}}</pre>
            </template>
            <template v-slot:loading>
                Simulation de la condition sur l'intégralité des licenses Yodaforex... Veuillez patienter...
            </template>
        </v-data-table>
        <v-btn v-if="!hideRefreshButton" @click="refresh">Lancer la simulation</v-btn>
        <v-snackbar :bottom="true" color="error" v-model="error">
            Une erreur est survenue.
            <v-btn @click="error=false" dark text>Fermer</v-btn>
        </v-snackbar>
    </div>
</template>

<script>
	export default {
		name: "RequirementSimulationTable",
		props: ['requirements', 'hideRefreshButton'],
		data() {
			return {
				headers: [
					{value: "name", text: "Nom"},
					{value: "companyName", text: "Société"},
					{value: "license", text: "License"},
					{value: "eligible", text: "Éligible"}
				],
        options: {
          sortBy: ["eligible"],
          sortDesc: [true]
        },
				error: false,
				items: [],
				loading: false
			}
		},
        methods: {
			refresh() {
				this.loading = true;
				this.$http.post(this.$config.apiUrl + "/admin/simulations/requirements", this.requirements)
					.then(r => this.items = r.data)
					.catch(e => this.error = true)
					.finally(() => this.loading = false)
			}
		}
	}
</script>

<style scoped>

</style>
