<template>
    <span>
        <span v-for="(r,pos) in requirements.requirements" :key="'r'+pos">
          <span v-if="requirements.requirements">
            <span class="binary-op"
                  v-if="pos > 0 && pos < requirements.requirements.length"> {{requirements.type}} </span>
            <span class="subject">{{r.subject}}<span class="subject-key" v-if="r.keyName">({{r.keyName}})</span>
                <span class="operator">&nbsp;{{r.operator}}&nbsp;</span>
                <span class="value">"{{r.value}}"</span>
            </span>
          </span>
        </span>
        <span v-for="(rg,pos) in requirements.requirementGroups" :key="'rg'+pos">
          <span v-if="requirements.requirementGroups">
            <span class="binary-op"
                  v-if="requirements.requirements.length > 0 || (pos > 0 && pos.requirements.requirements.length)"> {{requirements.type}} </span>
            <span><span class="parenthesis">(</span><requirements-label :requirements="rg"/><span
                    class="parenthesis">)</span></span>
            </span>
        </span>
    </span>
</template>

<script>
	export default {
		name: "RequirementsLabel",
		props: ['requirements']
	}
</script>

<style scoped>
    .binary-op {
        font-weight: bolder;
        color: orange;
    }

    .operator {
        color: gray;
        font-style: italic;
    }

    .subject {
        color: darkgreen;
    }

    .subject-key {
        font-size: smaller;
    }

    .value {
        color: cornflowerblue;
    }

    .parenthesis {
        color: red;
        font-weight: bolder;
        margin: 0 .2em;
    }
</style>
