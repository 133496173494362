import Vue from 'vue'
import App from './App.vue'
import router from './router'
import config from './config'
import axios from 'axios'
import vuetify from './plugins/vuetify';
import store from './store'
import YfxCloudAuth from '@yodatech/vue-yfx-cloud-auth'
import YfxLicense from '@yodatech/vue-yfx-license'
import './plugins/vue-moment'

Vue.config.productionTip = false
Vue.prototype.$config = config
Vue.prototype.$http = axios

Vue.use(YfxCloudAuth, {axios: axios, store: store, serviceName: "codepatch", ...config.authConfig})
Vue.use(YfxLicense, {axios: axios})

new Vue({
	router,
	vuetify,
	store,
	render: h => h(App)
}).$mount('#app')
